import "../styles/Carousel.scss";
import { Carousel } from "react-bootstrap";
import Bonus from "../media/carousel_reparatur.webp";
import Carousel1 from "../media/carousel_item_1.webp";
import Carousel2 from "../media/carousel_item_2.webp";
import Carousel3 from "../media/carousel_item_3.webp";

export const HomeCarousel = () => {
  return (
    <Carousel>
      <Carousel.Item>
        <a target="_blank" rel="noreferrer" href="https://www.reparaturbonus.at/?tx_sccompanysearch_companysearch%5Blocation%5D=Neud%C3%B6rfl%2C%20%C3%96sterreich&tx_sccompanysearch_companysearch%5Bradius%5D=5&tx_sccompanysearch_companysearch%5Bcompanytitle%5D=Complete%20IT%20OG&tx_sccompanysearch_companysearch%5Blat%5D=47.79624740000001&tx_sccompanysearch_companysearch%5Blng%5D=16.2971102">
          <img className="d-block w-100" src={Bonus} alt="Reaparaturbonus" />
        </a>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={Carousel1} alt="First slide" />
        <Carousel.Caption>
          <h2>Wir kümmern uns um Ihre Website</h2>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={Carousel2} alt="Second slide" />
        <Carousel.Caption className="caption-right">
          <h2>Konfiguration und Bau Ihres PCs</h2>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={Carousel3} alt="Third slide" />
        <Carousel.Caption className="caption-right">
          <h2>Planung und Installation Ihres Netzwerks</h2>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

import { useEffect } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import MaximilianProfile from "../media/maximilian-profile.webp";
import DavidProfile from "../media/david-profile.webp";
import "../styles/About.scss";

export const About = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <Container>
      <Row className="container-row">
        <h2>Unser Team</h2>
      </Row>
      <Row className="profile-row-top">
        <Col md={2}>
          <Image
            src={DavidProfile}
            alt="David Reinecker Profile"
            className="profile-image"
            roundedCircle="true"
          />
        </Col>
        <Col md={4}>
          <h3 className="text heading">David Reinecker</h3>
          <p className="text">
            <a href="mailto:david.reinecker@completeit.at">
              david.reinecker@completeit.at
            </a>
          </p>
        </Col>
      </Row>
      <Row className="profile-row-bottom">
        <Col md={2}>
          <Image
            src={MaximilianProfile}
            alt="Maximilian Guttmann Profile"
            className="profile-image"
            roundedCircle="true"
          />
        </Col>
        <Col md={4}>
          <h3 className="text heading">Maximilian Guttmann</h3>
          <p className="text">
            <a href="mailto:maximilian.guttmann@completeit.at">
              maximilian.guttmann@completeit.at
            </a>
          </p>
        </Col>
      </Row>
      <Row className="container-row">
        <h2>Unser Unternehmen</h2>
      </Row>
      <Row className="company-text">
        <Col md={7}>
          <p>
            Wir in unserem Unternehmen sind Spezialisten auf dem Gebiet der
            Erstellung und des Betriebs von Webseiten sowie der Implementierung
            von IT-Infrastrukturen für unsere Kunden. Wir verfügen über ein
            erfahrenes und engagiertes Team von Fachleuten, das stets darum
            bemüht ist, den Bedürfnissen unserer Kunden gerecht zu werden.
          </p>
        </Col>
      </Row>
      <Row className="company-text">
        <Col md={7}>
          <p>
            Unsere Kunden schätzen die hohe Qualität unserer Arbeit, sowie unser
            Engagement für ihre individuellen Bedürfnisse. Wir arbeiten eng mit
            unseren Kunden zusammen, um sicherzustellen, dass ihre Webseiten und
            IT-Systeme stets auf dem neuesten Stand sind und den sich ständig
            ändernden Anforderungen gerecht werden. Wenn Sie auf der Suche nach
            einem zuverlässigen Partner für die Erstellung Ihrer Webseite oder
            die Implementierung Ihrer IT-Infrastruktur sind, dann sind Sie bei
            uns genau richtig.
          </p>
        </Col>
      </Row>
    </Container>
  );
};

import { useEffect } from "react";
import { Container } from "react-bootstrap";

export const Imprint = ({ title }) => {
  useEffect(() => {
    document.title = title;
  }, [title]);
  return (
    <Container>
      <h1 className="22479186" style={{ paddingTop: "10px" }}>
        Impressum
      </h1>
      <p className="22479186">
        Angaben gemäß Informationspflicht laut §5 E-Commerce Gesetz, §14
        Unternehmensgesetzbuch, §63 Gewerbeordnung und Offenlegungspflicht laut
        §25 Mediengesetz.
      </p>
      <p className="22479186">
        Complete IT OG
        <br />
        Johann Reitergasse 11, <br />
        7201 Neudörfl, <br />
        Österreich
      </p>
      <p className="22479186">
        <strong>Unternehmensgegenstand:</strong> Dienstleistungen der
        Informationstechnologie
        <br />
        <strong>UID-Nummer:</strong> ATU79498946
        <br />
        <strong>Firmenbuchnummer:</strong> FN 603721 a<br />
        <strong>Firmenbuchgericht:</strong> Landesgericht Eisenstadt
        <br />
        <strong>Firmensitz:</strong> 7201 Neudörfl
      </p>
      <p className="22479186">
        <strong>Tel.:</strong> <a href="tel:+4366475148874">+43 664 75148874</a>
        <br />
        <strong>E-Mail:</strong>{" "}
        <a href="mailto:office@completeit.at">office@completeit.at</a>
      </p>
      <p className="22479186">
        <strong>Mitglied bei:</strong> WKO Burgenland
        <br />
        <strong>Berufsrecht:</strong> Gewerbeordnung: www.ris.bka.gv.at
      </p>
      <p className="22479186">
        <strong>Aufsichtsbehörde/Gewerbebehörde:</strong> Bezirkshauptmannschaft
        Mattersburg
        <br />
        <strong>Verleihungsstaat:</strong> Österreich
      </p>
      <p className="22479186">
        <strong>Vertretungsbefugte Gesellschafter</strong>
        <br />
        David Reinecker, Maximilian Guttmann
      </p>

      <h2 id="eu-streitschlichtung" className="22479186">
        EU-Streitschlichtung
      </h2>
      <p>
        Gemäß Verordnung über Online-Streitbeilegung in
        Verbraucherangelegenheiten (ODR-Verordnung) möchten wir Sie über die
        Online-Streitbeilegungsplattform (OS-Plattform) informieren.
        <br />
        Verbraucher haben die Möglichkeit, Beschwerden an die Online
        Streitbeilegungsplattform der Europäischen Kommission unter{" "}
        <a
          className="22479186"
          rel="noreferrer"
          href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE"
          target="_blank"
        >
          https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home2.show&amp;lng=DE
        </a>{" "}
        zu richten. Die dafür notwendigen Kontaktdaten finden Sie oberhalb in
        unserem Impressum.
      </p>
      <p>
        Wir möchten Sie jedoch darauf hinweisen, dass wir nicht bereit oder
        verpflichtet sind, an Streitbeilegungsverfahren vor einer
        Verbraucherschlichtungsstelle teilzunehmen.
      </p>
      <h2
        id="haftung-fuer-inhalte-dieser-webseite"
        className="22479186"
      >
        Haftung für Inhalte dieser Website
      </h2>

      <h2 id="haftung-links-webseite" className="22479186">
        Haftung für Links auf dieser Website
      </h2>
      <p>
        Unsere Website enthält Links zu anderen Websites für deren Inhalt wir
        nicht verantwortlich sind. Haftung für verlinkte Websites besteht für
        uns nicht, da wir keine Kenntnis rechtswidriger Tätigkeiten hatten und
        haben, uns solche Rechtswidrigkeiten auch bisher nicht aufgefallen sind
        und wir Links sofort entfernen würden, wenn uns Rechtswidrigkeiten
        bekannt werden.
      </p>
      <p>
        Wenn Ihnen rechtswidrige Links auf unserer Website auffallen, bitte wir
        Sie uns zu kontaktieren. Sie finden die Kontaktdaten im Impressum.
      </p>
      <h2 id="urheberrechtshinweis" className="22479186">
        Urheberrechtshinweis
      </h2>
      <p>
        Alle Inhalte dieser Webseite (Bilder, Fotos, Texte, Videos) unterliegen
        dem Urheberrecht. Bitte fragen Sie uns bevor Sie die Inhalte dieser
        Website verbreiten, vervielfältigen oder verwerten wie zum Beispiel auf
        anderen Websites erneut veröffentlichen. Falls notwendig, werden wir die
        unerlaubte Nutzung von Teilen der Inhalte unserer Seite rechtlich
        verfolgen.
      </p>
      <p>
        Sollten Sie auf dieser Webseite Inhalte finden, die das Urheberrecht
        verletzen, bitten wir Sie uns zu kontaktieren.
      </p>

      <h2 id="bildernachweis" className="22479186">
        Bildernachweis
      </h2>
      <p>
        Die Bilder, Fotos und Grafiken auf dieser Webseite sind urheberrechtlich
        geschützt.
      </p>
      <p>Alle Texte sind urheberrechtlich geschützt.</p>
      <p>
        Quelle: Erstellt mit dem  <a href="https://www.adsimple.at/impressum-generator/" target="_blank" rel="noreferrer">Impressum Generator von AdSimple</a>
      </p>
    </Container>
  );
};
